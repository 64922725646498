<template>
  <b-row>
    <!-- <b-col sm="10" md="7" lg="7" class=""> -->
    <b-col cols="12">
      <div class="card px-lg-4 px-md-3 px-2 py-3">
        <div class="d-flex justify-content-between">
          <div>
            <h6 class="">Client</h6>
            <!-- <b-form-input id="client" placeholder="" /> -->
            <h5
              class="bold"
              v-if="
                assignmentData &&
                assignmentData.client &&
                assignmentData.client.name
              "
            >
              {{ assignmentData.client.name }}
            </h5>
          </div>
          <b-button variant="outline-primary" pill>
            Paid Consultation
          </b-button>
        </div>

        <div class="mt-2">
          <h4 class="bold">Project Details</h4>
          <div v-if="assignmentData">
            <b-row class="mt-2">
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="">Project Type</h6>
                <h5 class="bold">
                  <span v-if="assignmentData.candidate_type === 'Executive'">
                    Executive Search
                  </span>
                  <span v-else> Expert Search </span>
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="">Assignment Title</h6>
                <h5 class="bold">{{ assignmentData.title || "Not Given" }}</h5>
              </b-col>
              <b-col sm="12">
                <h6 class="mt-1">Description</h6>
                <h5
                  class="bold"
                  v-if="assignmentData && assignmentData.description"
                >
                  {{ assignmentData.description || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Number of Required Candidates.</h6>
                <h5 class="bold">
                  {{ assignmentData.required_candidate || 0 }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Expiry Date</h6>
                <h5 class="bold">
                  {{ assignmentData.tenture || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Experience Required.</h6>
                <h5 class="bold">
                  {{ assignmentData.required_experience || 0 }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Industry</h6>
                <h5 class="bold">
                  {{ assignmentData.industry || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Function</h6>
                <h5 class="bold">
                  {{ assignmentData.function || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Sub Function</h6>
                <h5 class="bold">
                  {{ assignmentData.sub_function || "Not Given" }}
                </h5>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col sm="12">
                <h4 class="bold">Location Details</h4>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Address 1</h6>
                <h5 class="bold">
                  {{ assignmentData.address_1 || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Address 2</h6>
                <h5 class="bold">
                  {{ assignmentData.address_2 || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">City</h6>
                <h5 class="bold">{{ assignmentData.city || "Not Given" }}</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">State</h6>
                <h5 class="bold">{{ assignmentData.state || "Not Given" }}</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">ZIP CODE</h6>
                <h5 class="bold">{{ assignmentData.zip || "Not Given" }}</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Country</h6>
                <h5 class="bold">
                  {{ assignmentData.country || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Is Remote working allowed ?</h6>
                <h5 class="bold" v-if="assignmentData.remote === 'false'">
                  No
                </h5>
                <h5 class="bold" v-else>Yes</h5>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col sm="12">
                <h4 class="bold">Payment Details</h4>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Hourly Pricing (Phone)</h6>
                <h5 class="bold">
                  {{ assignmentData.hourly_price_phone || "Not Given" }}
                </h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Hourly Pricing (Face to Face)</h6>
                <h5 class="bold">
                  {{ assignmentData.hourly_price_face || "Not Given" }}
                </h5>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "@/libs/axios"
import { BButton, BCol, BRow } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      assignmentData: {},
    }
  },
  mounted() {
    this.getAssignmentData()
  },
  methods: {
    getAssignmentData() {
      axios
        .get(`/assignment/${this.$route.params.id}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Assignment List!`,
            },
          })

          this.assignmentData = res.data
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}
.bold {
  font-weight: 700;
}
.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.file_bg {
  background-color: #dcdcdc;
}
</style>
