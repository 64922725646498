const errorResponse = (data, router) => {
  if (data.response) {
    console.log(data.response.status)
    if (
      // eslint-disable-next-line operator-linebreak
      data.response.status === 401 &&
      process.env.NODE_ENV !== "development"
    ) {
      localStorage.clear()
      router.push({
        path: "/",
      })
    }
  }
}
export default errorResponse
